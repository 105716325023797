/** @jsxImportSource @emotion/react */
import type { Interpolation, Theme } from "@emotion/react";
import { ButtonBase, ButtonBaseProps, CircularProgress, Collapse, useTheme } from "@mui/material";
import React, { CSSProperties } from "react";

export interface ISharpButton extends Omit<ButtonBaseProps, "style"> {
	children: any;
	style?: CSSProperties;
	loading?: boolean;
	variant?: "filled" | "filled-green" | "bordered" | "text" | "app-primary" | "app-bordered";
	startIcon?: any;
	endIcon?: any;
	[key: string]: any;
}

const SharpButton = ({ children, style, variant, startIcon, endIcon, loading, disabled, ...others }: ISharpButton) => {
	const theme = useTheme();

	let baseStyle: Interpolation<Theme>;
	switch (variant) {
		case "text":
			baseStyle = {
				fontSize: 16,
				fontWeight: "bold",
				display: "flex",
				alignItems: "center",
				// border: "1px solid black",
				borderRadius: 12,
				padding: 16,
				textTransform: "uppercase",
			};
			break;

		case "bordered":
			baseStyle = {
				fontSize: 16,
				fontWeight: "bold",
				borderRadius: 12,
				width: "100%",

				display: "flex",
				alignItems: "center",
				border: "1px solid black",
				padding: 16,
				textTransform: "uppercase",

				":hover": {
					background: "rgba(0, 0, 0, 0.05)",
				},
			};
			break;
		case "app-bordered":
			baseStyle = {
				fontSize: 16,
				width: "100%",
				fontWeight: "bold",
				display: "flex",
				alignItems: "center",
				border: "1px solid rgba(12, 25, 23, 0.12)",
				borderRadius: 8,
				padding: 16,
				textTransform: "uppercase",
			};
			break;
		case "filled-green":
		// baseStyle = {
		//   width: "100%",
		//   padding: 11,
		//   background: theme.palette.primary.main,
		//   color: "black",
		//   textTransform: "uppercase",
		//   fontSize: 16,
		//   fontWeight: "bold",
		//   display: "flex",
		//   alignItems: "center",
		// };
		// break;
		case "filled":
		case "app-primary":
		default:
			baseStyle = {
				width: "100%",
				padding: "16px 51px",
				background: "radial-gradient(294.17% 294.17% at 100% 100%, #0EFDC7 0%, #F1FFFC 100%)",
				boxShadow: "0px 6px 2px -4px rgba(14, 14, 44, 0.1), inset 0px 0px 0px rgba(14, 14, 44, 0.4)",
				color: "black",
				textTransform: "uppercase",
				fontSize: 16,
				fontWeight: "bold",
				display: "flex",
				borderRadius: 8,
				alignItems: "center",
				border: "none",

				":hover": {
					background: `radial-gradient(294.17% 294.17% at 100% 100%, ${theme.palette.primary["400"]} 0%, #F1FFFC 100%)`,
				},
			};
			break;
		// case "filled":
		//   baseStyle = {
		//     width: "100%",
		//     padding: 21,
		//     background: "black",
		//     color: theme.palette.primary.main,
		//     textTransform: "uppercase",
		//     fontSize: 16,
		//     fontWeight: "bold",
		//     display: "flex",
		//     alignItems: "center",
		//     border: "1px solid black",
		//   };
	}

	return (
		<ButtonBase
			disabled={loading || disabled}
			css={{
				...baseStyle,
				...style,
				opacity: disabled || false ? 0.5 : 1,
				transaction: "all 200ms ease-in-out",
				alignItems: "center",
				display: "flex",
			}}
			{...others}
		>
			<React.Fragment>
				<Collapse
					in={startIcon !== undefined || loading}
					css={{ marginRight: loading || startIcon ? 10 : 0, transition: "all 200ms ease-in-out" }}
				>
					{loading ? <CircularProgress color="secondary" size={20} /> : startIcon}
				</Collapse>
			</React.Fragment>

			{children}
			{endIcon && (
				<React.Fragment>
					<span style={{ marginLeft: 10 }} />
					{endIcon}
				</React.Fragment>
			)}
		</ButtonBase>
	);
};

export default SharpButton;
